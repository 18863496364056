import { Box, Stack } from '@chakra-ui/react'
import React from 'react'
import Nav from './Nav'
import Product from './Components/Product5'

const Home = () => {
  return (
    <Box>
     
      <Product/>
    </Box>
  )
}

export default Home
