import React from 'react';

// import './App.css';

import { Box } from '@chakra-ui/react';

import AllRoutes from './Ui/Components/AllRoutes';





// import Navbarmenu from './components/menu/Navbarmenu';

function App() {
  return (
    <Box> 
       <AllRoutes/>



    </Box>
  );
}

export default App;


// {/* <Router basename="/">

// {/* Add Menu Component */}
// <Navbarmenu />

// </Router> */}